import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  DataTable,
  DataTableRow,
  DataTableHeader,
  DataTableBody,
  DataTableValueBlock,
  DataTableCodeBlock,
  Link,
  PageNavigation,
  Paragraph,
  PlatformTab,
  Section,
} from '../../../../components';

const WebTab = () => {
  const data = useStaticQuery(graphql`
    query elevationWebResources {
      allElevationsYaml {
        edges {
          node {
            elevations {
              name
              value
              css_var
            }
          }
        }
      }
    }
  `);

  return (
    <PlatformTab>
      <PageNavigation links={['Z-Index', 'Elevation']} />

      <Section title="Z-Index">
        <Paragraph>
          Use these variables for the content area of your interface.
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allElevationsYaml.edges[0].node.elevations
              .filter(elevation => elevation.name.startsWith('Content'))
              .map(item => {
                return (
                  <DataTableRow layout="thirds" key={item.css_var}>
                    <DataTableValueBlock>{item.name}</DataTableValueBlock>
                    <DataTableValueBlock>{item.value}</DataTableValueBlock>
                    <DataTableCodeBlock>{item.css_var}</DataTableCodeBlock>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTable>
      </Section>

      <Section title="Elevation">
        <Paragraph>
          Use these variables for expected interface elements like{' '}
          <Link href="/components/dialogs/modal" isDesignCodeLink>
            Modals
          </Link>
          ,{' '}
          <Link href="/components/notifications/toast" isDesignCodeLink>
            Toasts
          </Link>{' '}
          and{' '}
          <Link href="/components/notifications/notice" isDesignCodeLink>
            Notices
          </Link>
          .
        </Paragraph>
        <DataTable>
          <DataTableHeader>
            <DataTableRow layout="thirds">
              <th>Name</th>
              <th>Value</th>
              <th>Variable</th>
            </DataTableRow>
          </DataTableHeader>
          <DataTableBody>
            {data.allElevationsYaml.edges[0].node.elevations
              .filter(elevation =>
                elevation.css_var.startsWith('var(--elevation')
              )
              .map(item => {
                return (
                  <DataTableRow layout="thirds" key={item.css_var}>
                    <DataTableValueBlock>{item.name}</DataTableValueBlock>
                    <DataTableValueBlock>{item.value}</DataTableValueBlock>
                    <DataTableCodeBlock>{item.css_var}</DataTableCodeBlock>
                  </DataTableRow>
                );
              })}
          </DataTableBody>
        </DataTable>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
