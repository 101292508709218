import React from 'react';
import WebTab from './_code-web';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Z-Index & Elevation"
      subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Variables"
        tierThree="Z-Index & Elevation"
      />

      <PlatformTabs pageType="resources" platforms={{ web: <WebTab /> }} />
    </PageWithSubNav>
  );
};

export default IndexPage;
